import { Col, Row, Container } from "react-bootstrap";
import './components_css/StudioContent.css';
import 'aos/dist/aos.css';
import LanguageContext from './LanguageContext';
import React, { useState, useContext } from "react"

function StudioContent(){

    const LanguageContextValue = useContext(LanguageContext)

    function convertJSONToHTML(jsonKey) {
        return{
            __html: LanguageContextValue.language[jsonKey]
        };
    };

    return(
        <Container fluid className="StudioContent-container-fluid">
            <Container className="StudioContent-container">
                <Row>
                    <Col lg={6} sm={12}>
                        <div className="left"
                            data-aos="zoom-in">
                            <h3><span>+ </span></h3>
                        </div>
                        <div className="right"
                            data-aos="fade-down">
                            <h3>{LanguageContextValue.language['home-studio-a-h3-un-mot']}</h3>
                            <p>
                                {LanguageContextValue.language['home-studio-a-p-un-mot']}
                            </p>
                            <h4>{LanguageContextValue.language['home-studio-a-h4-fret']}</h4>
                            <h5>{LanguageContextValue.language['home-studio-a-h5-identite']}</h5>
                            <p>
                                {LanguageContextValue.language['home-studio-a-p-slogan']}
                            </p>
                        </div>
                    </Col>
                    <Col lg={6} sm={12}>
                    <div className="left"
                        data-aos="zoom-in">
                        <h3><span>+ </span></h3>
                    </div>
                    <div className="right"
                        data-aos="fade-down">
                        <h3>{LanguageContextValue.language['home-studio-b-h3-fret']}</h3>
                        <p dangerouslySetInnerHTML={convertJSONToHTML('home-studio-b-p-fret')}>
                           
                        </p>
                        <a href="https://www.linkedin.com/in/beno%C3%AEt-langlais-48b713126/" className="linkedin"><span>+ </span>{LanguageContextValue.language['home-studio-b-href-cliquer']}</a>
                    </div>
                    
                    </Col>
                </Row>
                <Row>
                    <Col lg={6} sm={12}>
                        <div className="left"
                            data-aos="zoom-in">
                            <h3><span>+ </span></h3>
                        </div>
                        <div className="right fondateur"
                            data-aos="fade-up">
                            <h3>
                                {LanguageContextValue.language['home-studio-c-h3-benoit']}
                            </h3>
                            <p>
                                {LanguageContextValue.language['home-studio-c-p-benoit-1']}
                            </p>
                            <p>
                                {LanguageContextValue.language['home-studio-c-p-benoit-2']}
                            </p>
                            <p>
                                {LanguageContextValue.language['home-studio-c-p-benoit-3']}
                            </p>
                            <p>
                                {LanguageContextValue.language['home-studio-c-p-benoit-4']}
                            </p>
                        </div>
                    </Col>
                    <Col lg={6} sm={12}>
                        <div className="left"
                            data-aos="zoom-in">
                            <h3><span>+ </span></h3>
                        </div>
                        <div className="right fondateur"
                            data-aos="fade-up">
                            <h3>
                                {LanguageContextValue.language['home-studio-d-h3-david']}
                            </h3>
                            <p>
                                {LanguageContextValue.language['home-studio-d-p-1-david']}
                            </p>
                            <p dangerouslySetInnerHTML={convertJSONToHTML('home-studio-d-p-2-david')}>
                                
                            </p>
                            <p>
                                {LanguageContextValue.language['home-studio-d-p-3-david']}
                            </p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </Container>

    )
}

export default StudioContent;