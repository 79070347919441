import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import React, { useState, useContext } from "react"
import 'aos/dist/aos.css';
import Routes from './Routes';
import LanguageContext from './components/LanguageContext';

import fr from './languages/fr.json'
import en from './languages/en.json'

export default function App() {
  const [language, userChoice] = useState(fr) //LA METHODE ELLE MEME


  function toggleLanguage(){
    userChoice(en=>fr)
  }

  function toggleLanguage2(){
    userChoice(fr=>en)
  }

  const LanguageContextValue = {
    language: language,  //VALEUR UTILISER
    updateLanguage: userChoice //METHODE UTILISER
  }

  return (
    <React.StrictMode>
      <LanguageContext.Provider value={LanguageContextValue}>
        {/* <button onClick={toggleLanguage}>Français</button>
        <button onClick={toggleLanguage2}>English</button> */}
        <Routes />
      </LanguageContext.Provider>
    </React.StrictMode>
    
  );
}