// import React, {Component, useContext, useEffect} from 'react';
// import ReactDOM from 'react-dom';

// import LanguageContext from './LanguageContext';

// import { Col, Row, Container } from 'react-bootstrap';
// import './components_css/AudioPlayer.css';

// // react-simple-knob import
// import Knob from "react-simple-knob";

// //REACT-SLICK IMPORTS
// import "slick-carousel/slick/slick.css"; 
// import "slick-carousel/slick/slick-theme.css";
// import Slider from "react-slick";

// // NEW MP3 AFTER MASTERING
// import Above from '../audio/fret_music_mastered/Above.mp3';
// import Arie from '../audio/fret_music_mastered/Arie.mp3';
// import Aventure from '../audio/fret_music_mastered/Aventure.mp3';
// import Benjo from '../audio/fret_music_mastered/Benjo.mp3';
// import BrumeNoire from '../audio/fret_music_mastered/Brume Noire.mp3';
// import ByTheWay from '../audio/fret_music_mastered/By the way.mp3';
// import Cendres from '../audio/fret_music_mastered/Cendres.mp3';
// import Creation from '../audio/fret_music_mastered/Creation.mp3';
// import Crepuscule from '../audio/fret_music_mastered/Crepuscule.mp3';
// import DansLaGeuleDeLaBete from '../audio/fret_music_mastered/Dans la gueule de la bête.mp3';
// import DansLeSable from '../audio/fret_music_mastered/Dans le sable.mp3';
// import Darling from '../audio/fret_music_mastered/Darling.mp3';
// import Destination from '../audio/fret_music_mastered/Destination.mp3';
// import Deuil from '../audio/fret_music_mastered/Deuil.mp3';
// import Diction from '../audio/fret_music_mastered/Diction.mp3';
// import Ecorce from '../audio/fret_music_mastered/Écorce.mp3';
// import FeteDesmeres from '../audio/fret_music_mastered/Fête des Mères.mp3';
// import FeuFolat from '../audio/fret_music_mastered/Feu Folat.mp3';
// import Fret from '../audio/fret_music_mastered/FRET.mp3';
// import Harsul from '../audio/fret_music_mastered/Harsul.mp3';
// import Hope from '../audio/fret_music_mastered/Hope.mp3';
// import Imagine from '../audio/fret_music_mastered/Imagine.mp3';
// import Industry from '../audio/fret_music_mastered/Industry.mp3';
// import Jazzy from '../audio/fret_music_mastered/Jazzy.mp3';
// import LOdeurDuMartyr from "../audio/fret_music_mastered/L'Odeur du Martyr.mp3";
// import LaPattePropre from '../audio/fret_music_mastered/La Patte Propre.mp3';
// import LePuit from '../audio/fret_music_mastered/Le Puit.mp3';
// import LeSeuilDuVent from '../audio/fret_music_mastered/Le Seuil du Vent.mp3';
// import LesAnciens from '../audio/fret_music_mastered/Les Anciens.mp3';
// import Loin from '../audio/fret_music_mastered/Loin.mp3';
// import Madryades from '../audio/fret_music_mastered/Madryades.mp3';
// import MardiGras from '../audio/fret_music_mastered/Mardi Gras.mp3';
// import Monstres from '../audio/fret_music_mastered/Monstres.mp3';
// import Obscure from '../audio/fret_music_mastered/Obscure.mp3';
// import Patrick from '../audio/fret_music_mastered/Patrick.mp3';
// import Penombre from '../audio/fret_music_mastered/Pénombre.mp3';
// import Purification from '../audio/fret_music_mastered/Purification.mp3';
// import Runes from '../audio/fret_music_mastered/Runes.mp3';
// import Sacrifice from '../audio/fret_music_mastered/Sacrifice.mp3';
// import Source from '../audio/fret_music_mastered/Source.mp3';
// import StLouis from '../audio/fret_music_mastered/St-Louis.mp3';
// import Survie from '../audio/fret_music_mastered/Survie.mp3';
// import Theme from '../audio/fret_music_mastered/Thème.mp3';
// import Thurz from '../audio/fret_music_mastered/Thurz.mp3';
// import Travelling from '../audio/fret_music_mastered/Travellling.mp3';
// import Ukutele from '../audio/fret_music_mastered/Ukutélé.mp3';

// // import ImageTest from '../images/carrousel_images/CompressionTest/05.jpg';

// //REACT-SLICK Configuration
// const settings = {
//     dots: false,
//     infinite: false,
//     speed: 600,
//     slidesToShow: 7,
//     slidesToScroll: 5,
//     vertical: true,
//     adaptiveHeight: false,
//     draggable: true,
//     easing: 'linear',
//     focusOnSelect: true,
//     responsive: [
//         {
//           breakpoint: 600,
//           settings: {
//             slidesToShow: 5
//           }
//         },
//         {
//           breakpoint: 480,
//           settings: {
//             slidesToShow: 5
//           }
//         }
//       ],
//     swipeToSlide: false,
//     swipe: true,
//     touchMove: true,
//     touchTreshold: 5,
//     nextArrow: <SampleNextArrow />,
//     prevArrow: <SamplePrevArrow />
// };

// function SampleNextArrow(props) {
//     const { onClick } = props;
//     return (
//       <div
//         className="playlist-go-down"
//         onClick={onClick}
//         >
//             <span className="fas fa-angle-down" style={{color:"white"}}></span>
//         </div>
//     );
//   }

//   function SamplePrevArrow(props) {
//     const { onClick } = props;
//     return (
//         <div
//         className="playlist-go-top"
//         onClick={onClick}
//         >
//             <span className="fas fa-angle-up" style={{color:"white"}}></span>
//         </div>
//     );
//   }

// const songs = new Map();

// songs.set(0, [Above, "Above"]);
// songs.set(1, [Arie, "Arie"]);
// songs.set(2, [Aventure, "Aventure"]);
// songs.set(3, [Benjo, "Benjo"]);
// songs.set(4, [BrumeNoire, "Brume Noire"]);
// songs.set(5, [ByTheWay, "By the Way"]);
// songs.set(6, [Cendres, "Cendres"]);
// songs.set(7, [Creation, "Création"]);
// songs.set(8, [Crepuscule, "Crépuscule"]);
// songs.set(9, [DansLaGeuleDeLaBete, "Dans la Gueule de la Bête"]);
// songs.set(10, [DansLeSable, "Dans le Sable"]);
// songs.set(11, [Darling, "Darling"]);
// songs.set(12, [Destination, "Destination"]);
// songs.set(13, [Deuil, "Deuil"]);
// songs.set(14, [Diction, "Diction"]);
// songs.set(15, [Ecorce, "Écorce"]);
// songs.set(16, [FeteDesmeres, "Fête des Mères"]);
// songs.set(17, [FeuFolat, "Feu Folat"]);
// songs.set(18, [Fret, "FRET"]);
// songs.set(19, [Harsul, "Harsul"]);
// songs.set(20, [Hope, "Hope"]);
// songs.set(21, [Imagine, "Imagine"]);
// songs.set(22, [Industry, "Industry"]);
// songs.set(23, [Jazzy, "Jazzy"]);
// songs.set(24, [LOdeurDuMartyr, "L'Odeur du Martyr"]);
// songs.set(25, [LaPattePropre, "La Patte Propre"]);
// songs.set(26, [LePuit, "Le Puit"]);
// songs.set(27, [LeSeuilDuVent, "Le Seuil du Vent"]);
// songs.set(28, [LesAnciens, "Les Anciens"]);
// songs.set(29, [Loin, "Loin"]);
// songs.set(30, [Madryades, "Madryades"]);
// songs.set(31, [MardiGras, "Mardi Gras"]);
// songs.set(32, [Monstres, "Monstres"]);
// songs.set(33, [Obscure, "Obscure"]);
// songs.set(34, [Patrick, "Patrick"]);
// songs.set(35, [Penombre, "Pénombre"]);
// songs.set(36, [Purification, "Purification"]);
// songs.set(37, [Runes, "Runes"]);
// songs.set(38, [Sacrifice, "Sacrifice"]);
// songs.set(39, [Source, "Source"]);
// songs.set(40, [StLouis, "St-Louis"]);
// songs.set(41, [Survie, "Survie"]);
// songs.set(42, [Theme, "Thème"]);
// songs.set(43, [Thurz, "Thurz"]);
// songs.set(44, [Travelling, "Travelling"]);
// songs.set(45, [Ukutele, "Ukutélé"]);
// songs.set(46, [Ukutele, "Ukutélé"]);

//     // TODO:

//     //      [X] Click to open a full screen div that will contain the audio player itself 
//     //          
//     //      [X] Tracklist on the side with a vertical slide show (react-slick)
//     //      [X] Controllers
//     //      [X] Progress Bar (dragable to move song progress)
//     //      [ ] Volume Knob

// let audioSrcIndex = 0;
// let audioPlayer = new Audio;
// audioPlayer.id="audioPlayer";
// audioPlayer.src = songs.get(audioSrcIndex)[0];

// //Knob CSS
// const knobStyle = {
//     margin: "0",
//     padding: "0%",
//     height: "100px",
//     fontFamily: "Arial",
//     color: "#fff" // Sets font color of value and knob name
// }

// let spanSongTimeProgress = document.getElementsByClassName("spanSongTimeProgress");
// let spanSongTimeDuration = document.getElementsByClassName("spanSongTimeDuration");

// audioPlayer.ontimeupdate = () => {
//     if (window.location.href.includes("creations"))
//         spanSongTimeProgress[0].innerHTML = fancyTimeFormat(audioPlayer.currentTime);
// };

// audioPlayer.ondurationchange = () => {
//     if (window.location.href.includes("creations"))
//         spanSongTimeDuration[0].innerHTML = fancyTimeFormat(audioPlayer.duration);
// }

// function fancyTimeFormat(duration)
// {
//     // Hours, minutes and seconds
//     var hrs = ~~(duration / 3600);
//     var mins = ~~((duration % 3600) / 60);
//     var secs = ~~duration % 60;

//     // Output like "1:01" or "4:03:59" or "123:03:59"
//     var ret = "";

//     if (hrs > 0)
//         ret += "" + hrs + ":" + (mins < 10 ? "0" : "");

//     ret += "" + mins + ":" + (secs < 10 ? "0" : "");
//     ret += "" + secs;
//     return ret;
// }

// class AudioPlayer extends Component{

//     render(){



//         //  AUDIO CONTROLLERS FUNCTIONS
//         const playSong = () => {
//             audioPlayer.play();
//             document.getElementById('songNameOnScreenP').innerHTML = songs.get(audioSrcIndex)[1];
//         }

//         const pauseSong = () => {
//             audioPlayer.pause();
//         }

//         const previousSong = () => {
//             if(audioSrcIndex === 0){
//                 audioPlayer.src = songs.get(0)[0];
//                 audioSrcIndex = 0;
//             }
//             else
//                 audioPlayer.src = songs.get(--audioSrcIndex)[0];

//             audioPlayer.play();
//             document.getElementById('songNameOnScreenP').innerHTML = songs.get(audioSrcIndex)[1];
//         }

//         const nextSong = () => {
//             if(audioSrcIndex === songs.size - 1){
//                 audioPlayer.src = songs.get(0)[0];
//                 audioSrcIndex = 0;
//             }
//             else
//                 audioPlayer.src = songs.get(++audioSrcIndex)[0];

//             audioPlayer.play();
//             document.getElementById('songNameOnScreenP').innerHTML = songs.get(audioSrcIndex)[1];
//         }

//         //  AUDIO PROGRESS BAR

//         let progressBar = document.getElementById("audio_player_progress");

//         audioPlayer.addEventListener('timeupdate', (event)=>{
//             if(document.getElementById("audio-progression-value")!=null) {
//                 let elmnt = document.getElementById("audio-progression-value");
//                 let width = audioPlayer.currentTime / audioPlayer.duration * 100;
//                 elmnt.style.width = width + "%";
//             }else {
//                 audioPlayer.pause();
//             }
//         });

//         const testProgression = (e) => {
//             let rect = e.target.getBoundingClientRect();
//             let x = e.clientX - rect.left; //the one to use

//             let maxWidth = document.getElementById("audio-progression").offsetWidth;
//             let progressionToSlide = document.getElementById("audio-progression-value");

//             let width = x / maxWidth * 100;

//             //Updating the UI
//             progressionToSlide.style.width = width+"%";

//             //Updating the position on the song
//             audioPlayer.currentTime = (audioPlayer.duration / 100) * width;

//             // console.log("currentTime: " + audioPlayer.currentTime);
//             // console.log("duration: " + audioPlayer.duration);
//             // console.log("MaxWidth : " + maxWidth + "\nWidth: " + width);
//         }

//         const handleAudioVolume = (value) => {
//             audioPlayer.volume = parseFloat(value) / 100; //should be between 0 and 1
//         }



//         return(
//             <Container>
//                 <div id="audioplayer-top-adjustment-div"></div>
//                 <Row>
//                     <Col lg={9} md={9} sm={12} sm={{ order: 'last' }} xs={12} xs= {{ order:'last' }} className="player-col">
//                         <div className="audio-player-bg-div"></div>


//                             <div className="audio-player-controller-container" id="glassmorphism">

//                             <div className="songNameOnScreenDiv">
//                                 <p id="songNameOnScreenP"
//                                     style={{color:"white",
//                                             fontSize:"2rem",
//                                             textAlign:"center"}}>
//                                                 Fret Musique
//                                             </p>
//                                 <div className="audioplayer-volume-container">
//                                     <div className="knobAdditionalStyling">
//                                         <Knob
//                                             // name="Volume"
//                                             unit="%"
//                                             defaultPercentage={1}
//                                             onChange={handleAudioVolume}
//                                             bg="#fff"
//                                             fg="#00aeef"
//                                             mouseSpeed={5}
//                                             transform={ p => parseInt(p * 100, 10) }
//                                             style={knobStyle}
//                                             id="knobAdditionalStyling"
//                                         />
//                                     </div>
//                                 </div>
//                             </div>

//                             {/*  bar de temps (progression de l'audio)  */}
//                             <div id="audio-progression" onClick={testProgression}>
//                                 <div id="audio-progression-value"></div>
//                             </div>

//                                 <div style={{textAlign:"center"}}>
//                                     <span style={{color:"white", fontSize:"1.2em", paddingRight:"4em"}} className="spanSongTimeProgress">0:00</span>
//                                     <span className="control-mouse-hover" onClick={ previousSong }><i className="fas fa-fast-backward audio-controller-action" id="backward-button"></i></span>
//                                     <span className="control-mouse-hover" onClick={ playSong }><i className="fas fa-play audio-controller-action" id="play-button"></i></span>
//                                     <span className="control-mouse-hover" onClick={ pauseSong }><i className="fas fa-pause audio-controller-action" id="pause-button"></i></span>
//                                     <span className="control-mouse-hover" onClick={ nextSong }><i className="fas fa-fast-forward audio-controller-action" id="forward-button"></i></span>
//                                     <span style={{color:"white", fontSize:"1.2em", paddingLeft:"4em"}} className="spanSongTimeDuration">0:00</span>
//                                 </div>

//                             <div className="musique-presentation-div">
//                                 <p>
//                                     {/* {window.location.href} */}

//                                     {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut suscipit, elit id ultricies sagittis, orci dolor aliquam leo, vel congue tortor nunc sit amet mauris. Duis lacinia, ligula a aliquam condimentum, arcu ante bibendum eros, et semper lacus eros a velit. Morbi dictum dui nisl, id semper risus dignissim et. Vivamus id ipsum ante. Fusce et velit ipsum. Phasellus turpis dui, efficitur sit amet laoreet ac, ultrices in mauris. Nunc dapibus orci mi, laoreet sollicitudin metus hendrerit a. Maecenas non massa lacus. */}
//                                     Une mélodie, un souvenir, la nostalgie ou l’espoir, la musique sera toujours le véhicule ultime dans le domaine de la transmission d’émotions. <br></br>
//                                     La musique possède un langage universel qui va bien au-delà des mots humains. <br></br>
//                                     Si tu es triste, la musique te fera sourire, si tu es stressé, la musique t’offrira un véritable moment de détente.<br></br><br></br>
//                                     La musique est vivante, même les oiseaux chantent.<br></br><br></br>
//                                     Étant donné que la musique n’est pas une banalité sonore qui s’écoute mais bien une émotion qui doit se vivre, profitez de l’émotion véhiculée par ces créations afin d’atteindre l’immersion totale.<br></br><br></br>
//                                     Pour une musique à l’image de ton âme, FRET Musique. 

//                                 </p>
//                             </div>

//                             </div>

//                             {/* <div id="song-list-div"></div> */}

//                     </Col>
//                     <Col lg={3} md={3} sm={12} xs={12} className="playlist-col" >
//                         {/* <h2> Playlist </h2> */}
//                             <Slider {...settings} id="playListSlider" className="playlist-container-glassmorphism" style={{textAlign:"center"}}>
//                                 {this.addSongList()}
//                             </Slider>

//                     </Col>
//                 </Row>
//             </Container>
//         )    
//     }

//     addSongList(){

//         let playlistComponents = [];

//         for(let i = 0; i < songs.size -1 ; i ++){
//             playlistComponents.push(
//                 <div>
//                     <p 
//                         className="songLink" 
//                         id={songs.get(i)[1]}
//                         onClick={this.playSongFromPlaylistChoice.bind(this, songs.get(i)[0], songs.get(i)[1], i)}
//                         >
//                             {songs.get(i)[1]}
//                     </p>
//                 </div>
//             );
//         }

//         return playlistComponents;
//     }

//     playSongFromPlaylistChoice = (song, songName, newIndex) => {
//         audioPlayer.pause();
//         audioPlayer.src=song;
//         audioPlayer.play();
//         document.getElementById('songNameOnScreenP').innerHTML = songName;
//         audioSrcIndex = newIndex;
//     }

// }

// export default AudioPlayer;


import React, {Component, useContext, useEffect} from 'react';
import ReactDOM from 'react-dom';

import LanguageContext from './LanguageContext';

import { Col, Row, Container } from 'react-bootstrap';
import './components_css/AudioPlayer.css';

// react-simple-knob import
import Knob from "react-simple-knob";

//REACT-SLICK IMPORTS
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

// NEW MP3 AFTER MASTERING
import Above from '../audio/fret_music_mastered/Above.mp3';
import Arie from '../audio/fret_music_mastered/Arie.mp3';
import Aventure from '../audio/fret_music_mastered/Aventure.mp3';
import Benjo from '../audio/fret_music_mastered/Benjo.mp3';
import BrumeNoire from '../audio/fret_music_mastered/Brume Noire.mp3';
import ByTheWay from '../audio/fret_music_mastered/By the way.mp3';
import Cendres from '../audio/fret_music_mastered/Cendres.mp3';
import Creation from '../audio/fret_music_mastered/Creation.mp3';
import Crepuscule from '../audio/fret_music_mastered/Crepuscule.mp3';
import DansLaGeuleDeLaBete from '../audio/fret_music_mastered/Dans la gueule de la bête.mp3';
import DansLeSable from '../audio/fret_music_mastered/Dans le sable.mp3';
import Darling from '../audio/fret_music_mastered/Darling.mp3';
import Destination from '../audio/fret_music_mastered/Destination.mp3';
import Deuil from '../audio/fret_music_mastered/Deuil.mp3';
import Diction from '../audio/fret_music_mastered/Diction.mp3';
import Ecorce from '../audio/fret_music_mastered/Écorce.mp3';
import FeteDesmeres from '../audio/fret_music_mastered/Fête des Mères.mp3';
import FeuFolat from '../audio/fret_music_mastered/Feu Folat.mp3';
import Fret from '../audio/fret_music_mastered/FRET.mp3';
import Harsul from '../audio/fret_music_mastered/Harsul.mp3';
import Hope from '../audio/fret_music_mastered/Hope.mp3';
import Imagine from '../audio/fret_music_mastered/Imagine.mp3';
import Industry from '../audio/fret_music_mastered/Industry.mp3';
import Jazzy from '../audio/fret_music_mastered/Jazzy.mp3';
import LOdeurDuMartyr from "../audio/fret_music_mastered/L'Odeur du Martyr.mp3";
import LaPattePropre from '../audio/fret_music_mastered/La Patte Propre.mp3';
import LePuit from '../audio/fret_music_mastered/Le Puit.mp3';
import LeSeuilDuVent from '../audio/fret_music_mastered/Le Seuil du Vent.mp3';
import LesAnciens from '../audio/fret_music_mastered/Les Anciens.mp3';
import Loin from '../audio/fret_music_mastered/Loin.mp3';
import Madryades from '../audio/fret_music_mastered/Madryades.mp3';
import MardiGras from '../audio/fret_music_mastered/Mardi Gras.mp3';
import Monstres from '../audio/fret_music_mastered/Monstres.mp3';
import Obscure from '../audio/fret_music_mastered/Obscure.mp3';
import Patrick from '../audio/fret_music_mastered/Patrick.mp3';
import Penombre from '../audio/fret_music_mastered/Pénombre.mp3';
import Purification from '../audio/fret_music_mastered/Purification.mp3';
import Runes from '../audio/fret_music_mastered/Runes.mp3';
import Sacrifice from '../audio/fret_music_mastered/Sacrifice.mp3';
import Source from '../audio/fret_music_mastered/Source.mp3';
import StLouis from '../audio/fret_music_mastered/St-Louis.mp3';
import Survie from '../audio/fret_music_mastered/Survie.mp3';
import Theme from '../audio/fret_music_mastered/Thème.mp3';
import Thurz from '../audio/fret_music_mastered/Thurz.mp3';
import Travelling from '../audio/fret_music_mastered/Travellling.mp3';
import Ukutele from '../audio/fret_music_mastered/Ukutélé.mp3';

// import ImageTest from '../images/carrousel_images/CompressionTest/05.jpg';

//REACT-SLICK Configuration
const settings = {
    dots: false,
    infinite: false,
    speed: 600,
    slidesToShow: 7,
    slidesToScroll: 5,
    vertical: true,
    adaptiveHeight: false,
    draggable: true,
    easing: 'linear',
    focusOnSelect: true,
    responsive: [
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 5
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 5
            }
        }
    ],
    swipeToSlide: false,
    swipe: true,
    touchMove: true,
    touchTreshold: 5,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />
};

function SampleNextArrow(props) {
    const { onClick } = props;
    return (
        <div
            className="playlist-go-down"
            onClick={onClick}
        >
            <span className="fas fa-angle-down" style={{color:"white"}}></span>
        </div>
    );
}

function SamplePrevArrow(props) {
    const { onClick } = props;
    return (
        <div
            className="playlist-go-top"
            onClick={onClick}
        >
            <span className="fas fa-angle-up" style={{color:"white"}}></span>
        </div>
    );
}

const songs = new Map();

songs.set(0, [Above, "Above"]);
songs.set(1, [Arie, "Arie"]);
songs.set(2, [Aventure, "Aventure"]);
songs.set(3, [Benjo, "Benjo"]);
songs.set(4, [BrumeNoire, "Brume Noire"]);
songs.set(5, [ByTheWay, "By the Way"]);
songs.set(6, [Cendres, "Cendres"]);
songs.set(7, [Creation, "Création"]);
songs.set(8, [Crepuscule, "Crépuscule"]);
songs.set(9, [DansLaGeuleDeLaBete, "Dans la Gueule de la Bête"]);
songs.set(10, [DansLeSable, "Dans le Sable"]);
songs.set(11, [Darling, "Darling"]);
songs.set(12, [Destination, "Destination"]);
songs.set(13, [Deuil, "Deuil"]);
songs.set(14, [Diction, "Diction"]);
songs.set(15, [Ecorce, "Écorce"]);
songs.set(16, [FeteDesmeres, "Fête des Mères"]);
songs.set(17, [FeuFolat, "Feu Folat"]);
songs.set(18, [Fret, "FRET"]);
songs.set(19, [Harsul, "Harsul"]);
songs.set(20, [Hope, "Hope"]);
songs.set(21, [Imagine, "Imagine"]);
songs.set(22, [Industry, "Industry"]);
songs.set(23, [Jazzy, "Jazzy"]);
songs.set(24, [LOdeurDuMartyr, "L'Odeur du Martyr"]);
songs.set(25, [LaPattePropre, "La Patte Propre"]);
songs.set(26, [LePuit, "Le Puit"]);
songs.set(27, [LeSeuilDuVent, "Le Seuil du Vent"]);
songs.set(28, [LesAnciens, "Les Anciens"]);
songs.set(29, [Loin, "Loin"]);
songs.set(30, [Madryades, "Madryades"]);
songs.set(31, [MardiGras, "Mardi Gras"]);
songs.set(32, [Monstres, "Monstres"]);
songs.set(33, [Obscure, "Obscure"]);
songs.set(34, [Patrick, "Patrick"]);
songs.set(35, [Penombre, "Pénombre"]);
songs.set(36, [Purification, "Purification"]);
songs.set(37, [Runes, "Runes"]);
songs.set(38, [Sacrifice, "Sacrifice"]);
songs.set(39, [Source, "Source"]);
songs.set(40, [StLouis, "St-Louis"]);
songs.set(41, [Survie, "Survie"]);
songs.set(42, [Theme, "Thème"]);
songs.set(43, [Thurz, "Thurz"]);
songs.set(44, [Travelling, "Travelling"]);
songs.set(45, [Ukutele, "Ukutélé"]);
songs.set(46, [Ukutele, "Ukutélé"]);

// TODO:

//      [X] Click to open a full screen div that will contain the audio player itself 
//          
//      [X] Tracklist on the side with a vertical slide show (react-slick)
//      [X] Controllers
//      [X] Progress Bar (dragable to move song progress)
//      [ ] Volume Knob

let audioSrcIndex = 0;
let audioPlayer = new Audio;
audioPlayer.id="audioPlayer";
audioPlayer.src = songs.get(audioSrcIndex)[0];

//Knob CSS
const knobStyle = {
    margin: "0",
    padding: "0%",
    height: "100px",
    fontFamily: "Arial",
    color: "#fff" // Sets font color of value and knob name
}

let spanSongTimeProgress = document.getElementsByClassName("spanSongTimeProgress");
let spanSongTimeDuration = document.getElementsByClassName("spanSongTimeDuration");

audioPlayer.ontimeupdate = () => {
    if (window.location.href.includes("creations"))
        spanSongTimeProgress[0].innerHTML = fancyTimeFormat(audioPlayer.currentTime);
};

audioPlayer.ondurationchange = () => {
    if (window.location.href.includes("creations"))
        spanSongTimeDuration[0].innerHTML = fancyTimeFormat(audioPlayer.duration);
}

function fancyTimeFormat(duration)
{
    // Hours, minutes and seconds
    var hrs = ~~(duration / 3600);
    var mins = ~~((duration % 3600) / 60);
    var secs = ~~duration % 60;

    // Output like "1:01" or "4:03:59" or "123:03:59"
    var ret = "";

    if (hrs > 0)
        ret += "" + hrs + ":" + (mins < 10 ? "0" : "");

    ret += "" + mins + ":" + (secs < 10 ? "0" : "");
    ret += "" + secs;
    return ret;
}

// class AudioPlayer extends Component{

//     render(){

const AudioPlayer = () => {
    const LanguageContextValue = useContext(LanguageContext);



    //  AUDIO CONTROLLERS FUNCTIONS
    const playSong = () => {
        audioPlayer.play();
        document.getElementById('songNameOnScreenP').innerHTML = songs.get(audioSrcIndex)[1];
    }

    const pauseSong = () => {
        audioPlayer.pause();
    }

    const previousSong = () => {
        if(audioSrcIndex === 0){
            audioPlayer.src = songs.get(0)[0];
            audioSrcIndex = 0;
        }
        else
            audioPlayer.src = songs.get(--audioSrcIndex)[0];

        audioPlayer.play();
        document.getElementById('songNameOnScreenP').innerHTML = songs.get(audioSrcIndex)[1];
    }

    const nextSong = () => {
        if(audioSrcIndex === songs.size - 1){
            audioPlayer.src = songs.get(0)[0];
            audioSrcIndex = 0;
        }
        else
            audioPlayer.src = songs.get(++audioSrcIndex)[0];

        audioPlayer.play();
        document.getElementById('songNameOnScreenP').innerHTML = songs.get(audioSrcIndex)[1];
    }

    //  AUDIO PROGRESS BAR

    let progressBar = document.getElementById("audio_player_progress");

    audioPlayer.addEventListener('timeupdate', (event)=>{
        if(document.getElementById("audio-progression-value")!=null) {
            let elmnt = document.getElementById("audio-progression-value");
            let width = audioPlayer.currentTime / audioPlayer.duration * 100;
            elmnt.style.width = width + "%";
        }else {
            audioPlayer.pause();
        }
    });

    const testProgression = (e) => {
        let rect = e.target.getBoundingClientRect();
        let x = e.clientX - rect.left; //the one to use

        let maxWidth = document.getElementById("audio-progression").offsetWidth;
        let progressionToSlide = document.getElementById("audio-progression-value");

        let width = x / maxWidth * 100;

        //Updating the UI
        progressionToSlide.style.width = width+"%";

        //Updating the position on the song
        audioPlayer.currentTime = (audioPlayer.duration / 100) * width;

        // console.log("currentTime: " + audioPlayer.currentTime);
        // console.log("duration: " + audioPlayer.duration);
        // console.log("MaxWidth : " + maxWidth + "\nWidth: " + width);
    }

    const handleAudioVolume = (value) => {
        audioPlayer.volume = parseFloat(value) / 100; //should be between 0 and 1
    }

    const addSongList = () => {

        let playlistComponents = [];
    
        for(let i = 0; i < songs.size -1 ; i ++){
            playlistComponents.push(
                <div>
                    <p
                        className="songLink"
                        id={songs.get(i)[1]}
                        onClick={playSongFromPlaylistChoice.bind(this, songs.get(i)[0], songs.get(i)[1], i)}
                    >
                        {songs.get(i)[1]}
                    </p>
                </div>
            );
        }
    
        return playlistComponents;
    }
    
    const playSongFromPlaylistChoice = (song, songName, newIndex) => {
        audioPlayer.pause();
        audioPlayer.src=song;
        audioPlayer.play();
        document.getElementById('songNameOnScreenP').innerHTML = songName;
        audioSrcIndex = newIndex;
    }



    return(
        <Container>
            <div id="audioplayer-top-adjustment-div"></div>
            <Row>
                <Col lg={9} md={9} sm={12} sm={{ order: 'last' }} xs={12} xs= {{ order:'last' }} className="player-col">
                    <div className="audio-player-bg-div"></div>


                    <div className="audio-player-controller-container" id="glassmorphism">

                        <div className="songNameOnScreenDiv">
                            <p id="songNameOnScreenP"
                               style={{color:"white",
                                   fontSize:"2rem",
                                   textAlign:"center"}}>
                                Fret Musique
                            </p>
                            <div className="audioplayer-volume-container">
                                <div className="knobAdditionalStyling">
                                    <Knob
                                        // name="Volume"
                                        unit="%"
                                        defaultPercentage={1}
                                        onChange={handleAudioVolume}
                                        bg="#fff"
                                        fg="#00aeef"
                                        mouseSpeed={5}
                                        transform={ p => parseInt(p * 100, 10) }
                                        style={knobStyle}
                                        id="knobAdditionalStyling"
                                    />
                                </div>
                            </div>
                        </div>

                        {/*  bar de temps (progression de l'audio)  */}
                        <div id="audio-progression" onClick={testProgression}>
                            <div id="audio-progression-value"></div>
                        </div>

                        <div style={{textAlign:"center"}}>
                            <span style={{color:"white", fontSize:"1.2em", paddingRight:"4em"}} className="spanSongTimeProgress">0:00</span>
                            <span className="control-mouse-hover" onClick={ previousSong }><i className="fas fa-fast-backward audio-controller-action" id="backward-button"></i></span>
                            <span className="control-mouse-hover" onClick={ playSong }><i className="fas fa-play audio-controller-action" id="play-button"></i></span>
                            <span className="control-mouse-hover" onClick={ pauseSong }><i className="fas fa-pause audio-controller-action" id="pause-button"></i></span>
                            <span className="control-mouse-hover" onClick={ nextSong }><i className="fas fa-fast-forward audio-controller-action" id="forward-button"></i></span>
                            <span style={{color:"white", fontSize:"1.2em", paddingLeft:"4em"}} className="spanSongTimeDuration">0:00</span>
                        </div>

                        <div className="musique-presentation-div">
                            <p>
                                {/* {window.location.href} */}
                                {/* {LanguageContextValue.language['creations-audio-player-p-content']} */}
                                {LanguageContextValue.language['creations-audio-player-p-content'].split('\n').map((line, index) => (
                                    <React.Fragment key={index}>
                                        {line}
                                        <br />
                                    </React.Fragment>
                                ))}
                            </p>
                        </div>

                    </div>

                    {/* <div id="song-list-div"></div> */}

                </Col>
                <Col lg={3} md={3} sm={12} xs={12} className="playlist-col" >
                    {/* <h2> Playlist </h2> */}
                    <Slider {...settings} id="playListSlider" className="playlist-container-glassmorphism" style={{textAlign:"center"}}>
                        {addSongList()}
                    </Slider>

                </Col>
            </Row>
        </Container>
    )
}

export default AudioPlayer;