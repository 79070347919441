import { Col, Row, Container } from "react-bootstrap";

import './components_css/ServicesContent.css';
import ServiceImg01 from '../images/service-01.jpg';
import ServiceImg02 from '../images/service-02.jpg';
import ServiceImg03 from '../images/service-03.jpg';
import ServiceImg04 from '../images/service-04.jpg';
import ServiceImg05 from '../images/service-05.jpg';
import ServiceImg06 from '../images/service-06.jpg';
import ServiceImg07 from '../images/service-07.jpg';
import ServiceImg08 from '../images/service-08.jpg';
import RuneLaguz from '../images/Rune_Laguz.png';
import RuneMannaz from '../images/Rune_Mannaz.png';
import RuneHagalaz from '../images/Rune_Hagalaz.png';
import RunePerthro from '../images/Rune_Perthro.png';
import RuneAnsuz from '../images/Rune_Ansuz.png';
import RuneKenaz from '../images/Rune_Kenaz.png';
import RuneWunjo from '../images/Rune_Wunjo.png';

import 'aos/dist/aos.css';

import LanguageContext from './LanguageContext';
import React, { useState, useContext } from "react"

function ServicesContentTest(){

    const LanguageContextValue = useContext(LanguageContext)

    return(
        <Container fluid className="container-bg">
        <Container>
            <Row className="service-row-top_">
                <Col lg={3} md={6} sm={6} xs={12} className="testCol">
                   <div className="frame"
                        data-aos="zoom-in-down">
                        <div className="frame-title"
                            data-aos="zoom-in-right">
                            <h1><span>+ </span>{LanguageContextValue.language['home-services-sstitle-a']}</h1>
                        </div>
                        
                        <img src={ ServiceImg01 } className="backgroundImg"></img>

                        <div className="details">
                            <img src={RuneLaguz} className="runesImg"></img>
                            <h1>{LanguageContextValue.language['home-services-stitle-a']}</h1>
                            <p>
                                {LanguageContextValue.language['home-services-hidden-text-a']}
                            </p>
                        </div>
                    </div>
                </Col>
                <Col lg={3} md={6} sm={6} xs={12} className="testCol">
                    <div className="frame"
                        data-aos="zoom-in-down">
                        <div className="frame-title"
                            data-aos="zoom-in-right">
                            <h1><span>+ </span>{LanguageContextValue.language['home-services-sstitle-b']}</h1>
                        </div>
                        
                        <img src={ ServiceImg02 } className="backgroundImg"></img>

                        <div className="details">
                            <img src={RuneMannaz} className="runesImg"></img>
                            <h1>{LanguageContextValue.language['home-services-stitle-b']}</h1>
                            <p>
                                {LanguageContextValue.language['home-services-hidden-text-b']}
                            </p>
                        </div>
                    </div>
                </Col>
                <Col lg={3} md={6} sm={6} xs={12} className="testCol">
                    <div className="frame"
                        data-aos="zoom-in-down">
                        <div className="frame-title"
                            data-aos="zoom-in-right">
                            <h1><span>+ </span>{LanguageContextValue.language['home-services-sstitle-c']}</h1>
                        </div>
                        
                        <img src={ ServiceImg03 } className="backgroundImg"></img>

                        <div className="details">
                            <img src={RuneLaguz} className="runesImg"></img>
                            <h1>{LanguageContextValue.language['home-services-stitle-c']}</h1>
                            <p>
                            {LanguageContextValue.language['home-services-hidden-text-c']}
                            </p>
                        </div>
                    </div>
                </Col>
                <Col lg={3} md={6} sm={6} xs={12} className="testCol">
                    <div className="frame"
                        data-aos="zoom-in-down">
                        <div className="frame-title"
                            data-aos="zoom-in-right">
                            <h1><span>+ </span>{LanguageContextValue.language['home-services-sstitle-d']}</h1>
                        </div>
                        
                        <img src={ ServiceImg04 } className="backgroundImg"></img>

                        <div className="details">
                            <img src={RuneHagalaz} className="runesImg"></img>
                            <h1>{LanguageContextValue.language['home-services-stitle-d']}</h1>
                            <p>
                                {LanguageContextValue.language['home-services-hidden-text-d']}
                            </p>
                        </div>
                    </div>
                </Col>
            </Row>
            <Row className="service-row-bottom_">
                <Col lg={3} md={6} sm={6} xs={12} className="testCol">
                    <div className="frame"
                        data-aos="zoom-in-up">
                        <div className="frame-title"
                            data-aos="zoom-in-up">
                            <h1><span>+ </span>{LanguageContextValue.language['home-services-sstitle-e']}</h1>
                        </div>
                        
                        <img src={ ServiceImg05 } className="backgroundImg"></img>

                        <div className="details">
                            <img src={RunePerthro} className="runesImg"></img>
                            <h1>{LanguageContextValue.language['home-services-stitle-e']}</h1>
                            <p>
                                {LanguageContextValue.language['home-services-hidden-text-e']}
                            </p>
                        </div>
                    </div>
                </Col>
                <Col lg={3} md={6} sm={6} xs={12} className="testCol">
                    <div className="frame"
                        data-aos="zoom-in-up">
                        <div className="frame-title"
                            data-aos="zoom-in-up">
                            <h1><span>+ </span>{LanguageContextValue.language['home-services-sstitle-f']}</h1>
                        </div>
                        
                        <img src={ ServiceImg06 } className="backgroundImg"></img>

                        <div className="details">
                            <img src={RuneAnsuz} className="runesImg"></img>
                            <h1>{LanguageContextValue.language['home-services-stitle-f']}</h1>
                            <p>
                                {LanguageContextValue.language['home-services-hidden-text-f']}
                            </p>
                        </div>
                    </div>
                </Col>
                <Col lg={3} md={6} sm={6} xs={12} className="testCol">
                    <div className="frame"
                        data-aos="zoom-in-up">
                        <div className="frame-title"
                            data-aos="zoom-in-up">
                            <h1><span>+ </span>{LanguageContextValue.language['home-services-sstitle-g']}</h1>
                        </div>
                        
                        <img src={ ServiceImg07 } className="backgroundImg"></img>

                        <div className="details">
                            <img src={RuneKenaz} className="runesImg"></img>
                            <h1>{LanguageContextValue.language['home-services-stitle-g']}</h1>
                            <p>
                            {LanguageContextValue.language['home-services-hidden-text-g']}
                            </p>
                        </div>
                    </div>
                </Col>
                <Col lg={3} md={6} sm={6} xs={12} className="testCol">
                    <div className="frame"
                        data-aos="zoom-in-up">
                        <div className="frame-title"
                            data-aos="zoom-in-up">
                            <h1><span>+ </span>{LanguageContextValue.language['home-services-sstitle-h']}</h1>
                        </div>
                        
                        <img src={ ServiceImg08 } className="backgroundImg"></img>

                        <div className="details">
                            <img src={RuneWunjo} className="runesImg"></img>
                            <h1>{LanguageContextValue.language['home-services-stitle-h']}</h1>
                            <p>       
                                {LanguageContextValue.language['home-services-hidden-text-h']}
                            </p>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
        </Container>
    )
}

export default ServicesContentTest;