import React,{Component} from 'react';

import './components_css/VideoPlayer.css';

import { Switch, Route, NavLink, Link, HashRouter } from 'react-router-dom';

import Carousel from 'react-elastic-carousel';
import VideoCard from './VideoCard';
const breakPoints = [
    {width:1, itemsToShow: 1},
    {width:768, itemsToShow: 2},
    {width:1200, itemsToShow: 3},

]
class HollywoodVideo extends Component{
    
    render(){
        return(
            <div>
              <h1 style={{textAlign:'center', color:'white'}}>HollywoodVideo</h1>
                <div>

                </div>
                
                
                <div className="videoContainer">
                    
                    <Carousel 
                        breakPoints={breakPoints}
                        // easing="cubic-bezier(.33,.29,.71,.5)"
                        // tiltEasing="cubic-bezier(0.110, 1, 1.000, 0.210)"
                         transitionMs={600} 
                        >
                        <Link to="/realisations/hollywood"><VideoCard title={"Hollywood"} content={"Prises de son, création d'ambiances et de musiques, bruitages et post-production ainsi que le mix complet de l'audio du projet."} /></Link>
                        <VideoCard title={"Hollywood"} content={"Prises de son, création d'ambiances et de musiques, bruitages et post-production ainsi que le mix complet de l'audio du projet."} />
                        <VideoCard title={"Hollywood"} content={"Prises de son, création d'ambiances et de musiques, bruitages et post-production ainsi que le mix complet de l'audio du projet."} />
                        <VideoCard title={"Hollywood"} content={"Prises de son, création d'ambiances et de musiques, bruitages et post-production ainsi que le mix complet de l'audio du projet."} />
                        <VideoCard title={"Hollywood"} content={"Prises de son, création d'ambiances et de musiques, bruitages et post-production ainsi que le mix complet de l'audio du projet."} />
                        <VideoCard title={"Hollywood"} content={"Prises de son, création d'ambiances et de musiques, bruitages et post-production ainsi que le mix complet de l'audio du projet."} />
                    </Carousel>
                </div>
                
            </div>

    
        )    
    }
}

export default HollywoodVideo;