import { Col, Row, Container } from "react-bootstrap";
import './components/components_css/MediaContent.css';
import 'aos/dist/aos.css';
import './components/components_css/AudioPlayer.css'
import AudioPlayer from "./components/AudioPlayer";

function AudioPlayerApp(){
    return(
        <Container fluid id="audioPlayerContainer">
            <AudioPlayer/>
        </Container>
    )
}

export default AudioPlayerApp;