import React, {useState} from 'react';
import { useSpring, animated } from 'react-spring';

const calc = (x, y) => [-(y - window.innerHeight / 2) / 20, (x - window.innerWidth / 2) / 20, 1.02];
const trans = (x, y, s) => `perspective(600px) rotateX(${x}deg) rotateY(${y}deg) scale(${s})`;




// const CarrouselCard = ({ cNumber }) => <div className="CarrouselCard"><img src={ cNumber }></img></div>
const VideoCard = ({ title, setIsVisible, setTiltAnimation, tiltProps }) =>

    <animated.div class="card card0"
        onMouseMove={({ clientX: x, clientY: y }) => setTiltAnimation({ xys: calc(x, y) })}
        onMouseLeave={() => setTiltAnimation({ xys: [0, 0, 1] })}
        style={{ transform: tiltProps.xys.interpolate(trans) }}

        onClick={() => {
            setIsVisible(v => !v);
        }}>
            <div class="contour">
                <h3>{title}</h3>
                <div class="videoOverlay">
                    <div class="center">
                        <h2>{title}</h2>
                        <p className="play_icon">▷</p>
                    </div>
                </div>
            </div>
    </animated.div>

    //              ▷   ▶

export default VideoCard;





// REAL ONE BEFORE TESTS

// import React, {useState} from 'react';
// import { useSpring, animated } from 'react-spring';
//
// const calc = (x, y) => [-(y - window.innerHeight / 2) / 20, (x - window.innerWidth / 2) / 20, 1.02];
// const trans = (x, y, s) => `perspective(600px) rotateX(${x}deg) rotateY(${y}deg) scale(${s})`;
//
//
//
//
// // const CarrouselCard = ({ cNumber }) => <div className="CarrouselCard"><img src={ cNumber }></img></div>
// const VideoCard = ({ title, setIsVisible, setTiltAnimation, tiltProps }) =>
//
//     <animated.div class="card card0"
//         onMouseMove={({ clientX: x, clientY: y }) => setTiltAnimation({ xys: calc(x, y) })}
//         onMouseLeave={() => setTiltAnimation({ xys: [0, 0, 1] })}
//         style={{ transform: tiltProps.xys.interpolate(trans) }}
//
//         onClick={() => {
//             setIsVisible(v => !v);
//         }}>
//             <div class="contour">
//                 <h3>{title}</h3>
//                 <div class="videoOverlay">
//                     <div class="center">
//                         <h2>{title}</h2>
//                         <p className="play_icon">▷</p>
//                     </div>
//                 </div>
//             </div>
//     </animated.div>
//
//     //              ▷   ▶
//
// export default VideoCard;
//
