import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

import React, {Fragment} from 'react';
import Header from './components/Header';
import Services from './components/Services';
import ServicesContent from './components/ServicesContent';
import Media from './components/Media';
import MediaContent from './components/MediaContent';
import Studio from './components/Studio';
import StudioContent from './components/StudioContent';
import Partenaires from './components/Partenaires';
import PartenairesContent from './components/PartenairesContent';
import Contact from './components/Contact';
import ContactContent from './components/ContactContent';
import Footer from './components/Footer';

import Aos from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react';

function Home(){

    useEffect(()=>{
        Aos.init({
            // Global settings:
            disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
            startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
            initClassName: 'aos-init', // class applied after initialization
            animatedClassName: 'aos-animate', // class applied on animation
            useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
            disableMutationObserver: false, // disables automatic mutations' detections (advanced)
            debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
            throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)
            
          
            // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
            offset: 40, // offset (in px) from the original trigger point
            delay: 100, // values from 0 to 3000, with step 50ms
            duration: 900, // values from 0 to 3000, with step 50ms
            easing: 'ease-out', // default easing for AOS animations
            once: false, // whether animation should happen only once - while scrolling down
            mirror: false, // whether elements should animate out while scrolling past them
            anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation
          
          });
      }, [])
    
    return(

        <React.Fragment>
            <Header />

            <Services />
            <ServicesContent />

            <Media />
            <MediaContent />

            <Studio />
            <StudioContent />

            <Partenaires />
            <PartenairesContent />

            <Contact />
            <ContactContent />

            <Footer />
        </React.Fragment>
    )
}

export default Home;
