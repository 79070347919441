import React from "react"
import fr from '../languages/fr.json'
import en from '../languages/en.json'

//INTERFACE DE LA METHODE (COMME UN INTERFACE I GUESS)
export default React.createContext({
    language: "",
    updateLanguage: userChoice => {}
});



// import React, { useState, useContext } from "react"

// import fr from '../languages/fr.json'
// import en from '../languages/en.json'

// const LanguageContext = React.createContext()
// const LanguageUpdateContext = React.createContext()

// export function LanguageProvider({ children }) {
//     const [englishLanguage, setEnglishLanguage] = useState(true)

//     function toggleLanguage() {
//         setEnglishLanguage(prevEnglish => ! prevEnglish)
//     }
    
//     return(
//         <LanguageContext.Provider value={englishLanguage}>
//             <LanguageUpdateContext.Provider value={toggleLanguage}>
//                 { children }
//             </LanguageUpdateContext.Provider>
//         </LanguageContext.Provider>
//     )
// }