import { Parallax, ParallaxLayer } from '@react-spring/parallax';
import { Player } from 'video-react';
import BigPlayButton from "video-react/lib/components/BigPlayButton";
import './components_css/VideoLayer.css';
import HTMLReactParser from "html-react-parser";

const VideoLayer = ({ offsetData, speedData, txtColor, h1Txt, pTxt, videoPoster, videoSrc }) =>
    <ParallaxLayer
        horizontal={true}
        offset={offsetData}
        speed={speedData}
        className="div_parallax_not_reverse">
        <div className="div_parallax_container">

            <div className="div_text_content">

                    <h1 style={{color: txtColor}} className="h1_title">
                        {HTMLReactParser(h1Txt)}
                    </h1>


                    <p style={{color: txtColor}} className="p_description">
                        {HTMLReactParser(pTxt)}
                    </p>

            </div>


            <div className="div_player">
                <Player
                    playsInline
                    height="50%"
                    poster={videoPoster}
                    src={videoSrc}
                    className="videoOnPageClass">
                    <BigPlayButton position="center"/>
                </Player>
            </div>
        </div>
    </ParallaxLayer>

//              ▷   ▶

export default VideoLayer;
