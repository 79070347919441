import { Container } from "react-bootstrap";

import './components_css/Contact.css';


function Contact(){
    return(
        <Container fluid className="Contact-container"></Container>
    )
}

export default Contact;