import { Col, Row, Container } from "react-bootstrap";

import './components_css/MediaContent.css';

import RuneIsa from '../images/Rune_Isa.png';
import RuneUruz from '../images/Rune_Uruz.png';
import RuneNauthiz from '../images/Rune_Nauthiz.png';

import 'aos/dist/aos.css';

import LanguageContext from './LanguageContext';
import React, { useState, useContext } from "react"
import { Link } from 'react-router-dom';

function MediaContent(){

    const LanguageContextValue = useContext(LanguageContext)

    return(
        <Container fluid className="MediaContent-container">
                <Row>
                    <Col lg={4} md={4} sm={12} xs={12} className="left-col">
                        <div className="mediacontent-div">
                            <div className="mediacontent-title-div"
                                data-aos="zoom-in-left">
                                    <Link to="/realisations">
                                        <h2><span>+ </span>{LanguageContextValue.language['home-medias-link-realizations']}</h2>
                                    </Link>
                            </div>
                            <div 
                                data-aos="zoom-in-right">
                                <img src={RuneIsa}></img>
                            </div>
                        </div>
                    </Col>
                    <Col lg={4} md={4} sm={12} xs={12} className="mid-col">
                        <div className="mediacontent-div">
                            <div className="mediacontent-title-div"
                                data-aos="zoom-in-left">
                                <Link to="/creations">
                                    <h2><span>+ </span>{LanguageContextValue.language['home-medias-link-creations']}</h2>
                                </Link>
                            </div>
                            <div
                                data-aos="zoom-in-right">
                                <img src={RuneUruz}></img>
                            </div>
                        </div>
                    </Col>
                    <Col lg={4} md={4} sm={12} xs={12} className="right-col">
                        <div className="mediacontent-div">
                            <div className="mediacontent-title-div"
                                data-aos="zoom-in-left">
                                <Link to="/galerie">
                                    <h2><span>+ </span>{LanguageContextValue.language['home-medias-link-gallery']}</h2>
                                </Link>
                            </div>
                            <div
                                data-aos="zoom-in-right">
                                <img src={RuneNauthiz}></img>
                            </div>
                        </div>
                    </Col>
                </Row>
        </Container>
    )
}

export default MediaContent;