import React, { useState, useContext } from "react";
import './components_css/ContactForm.css';

import LanguageContext from './LanguageContext';

const ContactForm = () => {

  const LanguageContextValue = useContext(LanguageContext);

  const [name, setName] = useState("");
  const [company, setCompany] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const isEmail = () => {
    let mail = document.getElementById('not-mail');
    let regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    if(email.match(regex)){
      mail.style.display = 'none';
      return true;
    }else{
      mail.style.display = 'block';
      mail.style.animation = 'dongle 1s';
      setTimeout(() => {
        mail.style.animation = 'none';
      }, 1000);
      return false;
    }
  }

  const failMessage = (message) => {
    let formMess = document.querySelector('.form-message');

    formMess.innerHTML = message;
    formMess.style.opacity = '1';
    formMess.style.background = 'rgb(255,51,51)';
    formMess.style.color = 'white';
    formMess.style.fontWeight = '900';

    document.getElementById('name').classList.add('error');
    document.getElementById('email').classList.add('error');
    document.getElementById('message').classList.add('error');
  }

  const successMessage = () => {
    console.log('msg from successMessage');

    let formMess = document.querySelector('.form-message');

    // formMess.innerHTML = 'Message envoyé!  Nous vous recontacterons dès que possible.';
    formMess.innerHTML = LanguageContextValue.language['home-contact-form-succes-msg'];
    formMess.style.background = 'rgb(0,174,239)';
    formMess.style.opacity = '1';
    formMess.style.color = 'white';
    formMess.style.fontWeight = '900';

    document.getElementById('name').classList.remove('error');
    document.getElementById('email').classList.remove('error');
    document.getElementById('message').classList.remove('error');

    setTimeout(() => {
      formMess.style.opacity = '0';
    }, 5000);
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    if(name && isEmail() && message){
      sendFeedback("template_d23hqhw", {
        name,
        company,
        phone,
        email,
        message,
      });
    }else{
      // failMessage('Veuillez remplir correctement les champs requis *');
      failMessage(LanguageContextValue.language['home-contact-form-err-msg-b']);
    }
  };

  const sendFeedback = (templateId, variables) => {

    window.emailjs.send("service_ag57e3b", templateId, variables)
      .then((res) => {
        console.log("success from sendfeedback fonction");
        setName('');
        setCompany('');
        setPhone('');
        setEmail('');
        setMessage('');
        successMessage();
      })
      .catch(
        (err) =>{
          // failMessage("Une erreur s'est produite, veuillez réessayer.");
          failMessage(LanguageContextValue.language['home-contact-form-err-msg-a']);
        }
      );
  };

  return (
    <form className="contact-form">
      <h2>Contactez-nous</h2>
      <div className="form-content">
        <input
          type="text"
          id="name"
          name="name"
          onChange={(e) => setName(e.target.value)}
          placeholder={LanguageContextValue.language['home-contact-form-name']}
          value={name}
          autoComplete="off"
        />
        <input
          type="text"
          id="company"
          name="company"
          onChange={(e) => setCompany(e.target.value)}
          placeholder={LanguageContextValue.language['home-contact-form-company']}
          value={company}
        />
        <input
          type="text"
          id="phone"
          name="phone"
          onChange={(e) => setPhone(e.target.value)}
          placeholder={LanguageContextValue.language['home-contact-form-phone']}
          value={phone}
        />
        <div className="email-content">
          <label id="not-mail">{LanguageContextValue.language['home-contact-form-invalid-email']}</label>
          <input
            type="mail"
            id="email"
            name="email"
            onChange={(e) => setEmail(e.target.value)}
            placeholder={LanguageContextValue.language['home-contact-form-email']}
            value={email}
            autoComplete="off"
          />
        </div>
        <textarea
          id="message"
          name="message"
          onChange={(e) => setMessage(e.target.value)}
          placeholder={LanguageContextValue.language['home-contact-form-msg']}
          value={message}
        />
      </div>
      <input
        className="button"
        type="button"
        value={LanguageContextValue.language['home-contact-form-send']}
        onClick={handleSubmit}
      />
      <div className="form-message"></div>
    </form>
  );
};

export default ContactForm;