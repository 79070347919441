import React from 'react';


// const CarrouselCard = ({ cNumber }) => <div className="CarrouselCard"><img src={ cNumber }></img></div>
const CarrouselCard = ({ cNumber }) => <div className="CarrouselCard"
                                                style={{backgroundImage:`url(${cNumber})`,
                                                        backgroundRepeat:'no-repeat',
                                                        backgroundPosition:'bottom',
                                                        backgroundSize:'cover'
                                                        }}>
                                            <h1 style={{backgroundColor:"rgba(0,0,0,0.5)"}}>FRET MUSIQUE</h1>
                                        </div>

export default CarrouselCard;