import React,{ Component, useState, useContext, useEffect, useRef } from 'react';
import "../../node_modules/video-react/dist/video-react.css"; // import css
import { Player } from 'video-react';
import "./components_css/VideoPlayerNew.css";
import LanguageContext from "./LanguageContext";
import { Parallax, ParallaxLayer } from '@react-spring/parallax';

import Video01 from '../videos/realisations/01 - Bande-annonce - FRET Musique TEASER.mp4';
import Video02 from '../videos/realisations/02 - Cocktail de Noël de BioQuebec 2017.mp4';
import Video03 from '../videos/realisations/03 - Frédéric Dion - Le Couteau Parfait.mp4';
import Video04 from "../videos/realisations/04 - Identité sonore - Assassin's Creed Démo.mp4";
import Video05 from '../videos/realisations/05 - Musique Publicitaire - Add Ink Tattoo.mp4';
import Video06 from '../videos/realisations/06 - Splinter Cell Démo.mp4';
import Video07 from '../videos/realisations/07 - Musique publicitaire - LM Wind Power - 15 secondes.mp4';
import Video08 from '../videos/realisations/08 - Thème Principal - Fight Night.mp4';
import Video09 from '../videos/realisations/09 - Identité Sonore - Frédéric Dion - Les 3 Pôles de la sécurité.mp4';
import Video10 from '../videos/realisations/10 - Besoins audio complets - HOLLYWOOD.mp4';
import Video11 from '../videos/realisations/11 - Besoins audio complets - Ford Lévis.mp4';
import Video12 from '../videos/realisations/12 - Musique Publicitaire - Festidrag SEMA 1.mp4';
import Video13 from '../videos/realisations/13 - Musique Publicitaire - Festidrag SEMA 2.mp4';
import Video14 from "../videos/realisations/14 - Thème Principal - Fortune's Favor.mp4";

import Video15 from '../videos/realisations/15 – Musique Publicitaire – Fight Night Promo 2021.mp4';
import Video16 from '../videos/realisations/16 – Identité Sonore – ICI – Institut Coaching International.mp4';
import Video17 from '../videos/realisations/17 – Musique Publicitaire – Fight Night, Maintenant ou jamais.mp4';
import Video18 from '../videos/realisations/18 – Musique Publicitaire – Fight Night, Le Défi du Goon.mp4';

import Poster01 from '../images/Realisations/01 - Bande-annonce - FRET Musique TEASER.PNG';
import Poster02 from '../images/Realisations/02 - Identité sonore - Cocktail de Noël de BioQuebec 2017.PNG';
import Poster03 from '../images/Realisations/03 - Identité sonore - Frédéric Dion - Le Couteau Parfait.PNG';
import Poster04 from "../images/Realisations/04 - Identité sonore - Assassin's Creed Démo.PNG";
import Poster05 from "../images/Realisations/05 - Musique Publicitaire - Add'Ink Tattoo.PNG";
import Poster06 from "../images/Realisations/06 - Identité sonore - Splinter Cell Démo.PNG";
import Poster07 from '../images/Realisations/07 - Musique publicitaire - LM Wind Power - 15 secondes.PNG';
import Poster08 from '../images/Realisations/08 - Thème Principal - Fight Night.PNG';
import Poster09 from '../images/Realisations/09 - Identité Sonore - Frédéric Dion - Les 3 Pôles de la sécurité.PNG';
import Poster10 from '../images/Realisations/10 - Besoins audio complets - HOLLYWOOD.png';
import Poster11 from '../images/Realisations/11 - Besoins audio complets - Lévis Ford.PNG';
import Poster12 from '../images/Realisations/12 - Musique Publicitaire - Festidrag SEMA 1.PNG';
import Poster13 from '../images/Realisations/13 - Musique Publicitaire - Festidrag SEMA 2.PNG';
import Poster14 from "../images/Realisations/14 - Thème Principal - Fortune's Favor.PNG";

import Poster15 from '../images/Realisations/15 – Musique Publicitaire – Fight Night Promo 2021.jpg';
import Poster16 from '../images/Realisations/16 – Identité Sonore – ICI – Institut Coaching International.png';
import Poster17 from '../images/Realisations/17 – Musique Publicitaire – Fight Night, Maintenant ou jamais.jpg';
import Poster18 from '../images/Realisations/18 – Musique Publicitaire – Fight Night, Le Défi du Goon.jpg';


import BigPlayButton from "video-react/lib/components/BigPlayButton";
import posterHollywood from '../images/Realisations/HOLLYWOOD_SCREENSHOT.png';
import VideoLayer from './VideoLayer';
import VideoLayerReverse from "./VideoLayerReverse";

import useWindowDimensions from './useWindowDimensions';
import {forEach} from "react-bootstrap/ElementChildren";

const VideoPlayerNew = () => {

    let allVideoOnThePage = document.getElementsByClassName("videoOnPageClass");//PARTIE POUR ESSAYE DARRETER UN VIDEO QUAND ON SWITCH

    const LanguageContextValue = useContext(LanguageContext);
    const ref = useRef();

    let currentVideoIndex = 0;

    const videoTotalCount = 18 - 2;

    function changeVideoBefore(){
        if(currentVideoIndex > 0){
            currentVideoIndex--;
            ref.current.scrollTo(currentVideoIndex);

            for(let i = 0; i < allVideoOnThePage.length; i++){
                allVideoOnThePage[i].childNodes[0].pause();
            }
        }
        console.log("test");
        console.log(document.getElementsByClassName("video-react-video"));
    }

    function changeVideoAfter(){
        console.log("testttt");
        if(currentVideoIndex <= videoTotalCount){
            currentVideoIndex++;
            ref.current.scrollTo(currentVideoIndex);

            for(let i = 0; i < allVideoOnThePage.length; i++){
                allVideoOnThePage[i].childNodes[0].pause();
            }
        }
        console.log("test");
        console.log(document.getElementsByClassName("video-react-video"));
    }

    window.addEventListener('locationchange', function(){
        if (window.location.href.includes("realisations")){
            for(let i = 0; i < allVideoOnThePage.length; i++){
                allVideoOnThePage[i].childNodes[0].pause();
            }
        }
    })

    //add a function that always listen and change scrolling to vertical on each
    //parallaxlayer and on the parallax itself and remove the Digit links


    // const { height, width } = useWindowDimensions();
    //
    // //now windowWidth return screenwidth in real time.
    //
    // window.addEventListener("resize", ()=> {
    //     let nav = document.getElementById("nav_div");
    //     let parallax = document.getElementById("theParallax");
    //     let parallaxLayer = document.getElementsByClassName("parallaxLayer");
    //
    //     if(width<746){
    //         nav.style.visibility = "hidden";
    //     }
    //     if(width==746||width>746){
    //         nav.style.visibility = "visible";
    //     }
    // })


    return(
        

        <div className="testHide">
            <div style={{background:"black",
                width:"65px",
                height:"62px",
                position:"fixed",
                top:"20px",
                left:"22px",
                borderRadius:"50px",
                zIndex:"10"
            }}
                
            />

            {/*<div style={{color:"white"}}>*/}
            {/*    width: {width} ~ height: {height}*/}
            {/*</div>*/}

            <div id="nav_div">
                <b>
                    <a onClick={ changeVideoBefore } >&#9665;</a>
                    <a onClick={ changeVideoAfter } >&#9655;</a>
                </b>

                    {/* <b>|
                        <a onClick={() => ref.current.scrollTo(0)} onClick={()=>console.log("test")}> 01 </a><span>|</span>
                        <a onClick={() => ref.current.scrollTo(1)} > 02 </a>|
                        <a onClick={() => ref.current.scrollTo(2)} > 03 </a>|
                        <a onClick={() => ref.current.scrollTo(3)} > 04 </a>|
                        <a onClick={() => ref.current.scrollTo(4)} > 05 </a>|
                        <a onClick={() => ref.current.scrollTo(5)} > 06 </a>|
                        <a onClick={() => ref.current.scrollTo(6)} > 07 </a>|
                        <a onClick={() => ref.current.scrollTo(7)} > 08 </a>|
                        <a onClick={() => ref.current.scrollTo(8)} > 09 </a>|
                        <a onClick={() => ref.current.scrollTo(9)} > 10 </a>|
                        <a onClick={() => ref.current.scrollTo(10)} > 11 </a>|
                        <a onClick={() => ref.current.scrollTo(11)} > 12 </a>|
                        <a onClick={() => ref.current.scrollTo(12)} > 13 </a>|
                        <a onClick={() => ref.current.scrollTo(13)} > 14 </a>|
                    </b> */}
            </div>

            <Parallax pages={18} style={{ top: '0', left: '0' }} horizontal={true} ref={ref} id="theParallax">

                <VideoLayer
                    offsetData={0}
                    speedData={2.5}
                    txtColor={'white'}
                    h1Txt={LanguageContextValue.language['realizations-title-01']}
                    pTxt={LanguageContextValue.language['realizations-description-01']}
                    videoPoster={Poster01}
                    videoSrc={Video01}
                    className="parallaxLayer"
                />

                <ParallaxLayer offset={0} speed={4.5} style={{zIndex:"-1"}}>
                    <h1 className="background_digits_white">01</h1>
                </ParallaxLayer>

                <ParallaxLayer offset={1} speed={2} style={{ backgroundColor: '#fff' }} />

                <VideoLayerReverse
                    offsetData={1}
                    speedData={0.2}
                    txtColor={'black'}
                    h1Txt={LanguageContextValue.language['realizations-title-02']}
                    pTxt={LanguageContextValue.language['realizations-description-02']}
                    videoPoster={Poster02}
                    videoSrc={Video02}
                    className="parallaxLayer"
                    style={{zIndex:"2"}}
                />
                <ParallaxLayer offset={1} speed={2.5} className="layer_digits_black">
                    <h1 className="background_digits_black">02</h1>
                </ParallaxLayer>


                <ParallaxLayer offset={2} speed={2} style={{ backgroundColor: '#000' }} />

                <VideoLayer
                    offsetData={2}
                    speedData={0.5}
                    txtColor={'white'}
                    h1Txt={LanguageContextValue.language['realizations-title-03']}
                    pTxt={LanguageContextValue.language['realizations-description-03']}
                    videoPoster={Poster03}
                    videoSrc={Video03}

                />



                <ParallaxLayer offset={3} speed={2} style={{ backgroundColor: '#fff' }} />

                <ParallaxLayer offset={2} speed={1.5} style={{zIndex:"1", width:"300px"}}>
                    <h1 style={{ background:"transparent", color:"#fff", fontSize:"35em", opacity:"0.1"}}>03</h1>
                </ParallaxLayer>

                <VideoLayerReverse
                    offsetData={3}
                    speedData={0.5}
                    txtColor={'black'}
                    h1Txt={LanguageContextValue.language['realizations-title-04']}
                    pTxt={LanguageContextValue.language['realizations-description-04']}
                    videoPoster={Poster04}
                    videoSrc={Video04}

                />

                <ParallaxLayer offset={3} speed={2.5} style={{zIndex:"11"}}>
                    <h1 style={{color:"#000", fontSize:"35em", opacity:"0.1", textAlign:"right"}}>04</h1>
                </ParallaxLayer>

                <ParallaxLayer offset={4} speed={2} style={{ backgroundColor: '#000' }} />

                <VideoLayer
                    offsetData={4}
                    speedData={0.5}
                    txtColor={'white'}
                    h1Txt={LanguageContextValue.language['realizations-title-05']}
                    pTxt={LanguageContextValue.language['realizations-description-05']}
                    videoPoster={Poster05}
                    videoSrc={Video05}

                />

                <ParallaxLayer offset={4} speed={2} style={{zIndex:"11"}}>
                    <h1 style={{color:"#fff", fontSize:"35em", opacity:"0.1"}}>05</h1>
                </ParallaxLayer>

                <ParallaxLayer offset={5} speed={2} style={{ backgroundColor: '#fff' }} />

                <VideoLayerReverse
                    offsetData={5}
                    speedData={0.5}
                    txtColor={'black'}
                    h1Txt={LanguageContextValue.language['realizations-title-06']}
                    pTxt={LanguageContextValue.language['realizations-description-06']}
                    videoPoster={Poster06}
                    videoSrc={Video06}

                />

                <ParallaxLayer offset={5} speed={1.5} style={{zIndex:"11"}}>
                    <h1 style={{color:"#000", fontSize:"35em", opacity:"0.1", textAlign:"right"}}>06</h1>
                </ParallaxLayer>

                <ParallaxLayer offset={6} speed={2} style={{ backgroundColor: '#000' }} />

                <VideoLayer
                    offsetData={6}
                    speedData={0.5}
                    txtColor={'white'}
                    h1Txt={LanguageContextValue.language['realizations-title-07']}
                    pTxt={LanguageContextValue.language['realizations-description-07']}
                    videoPoster={Poster07}
                    videoSrc={Video07}

                />

                <ParallaxLayer offset={6} speed={3.5} style={{zIndex:"11"}}>
                    <h1 style={{color:"#fff", fontSize:"35em", opacity:"0.1"}}>07</h1>
                </ParallaxLayer>

                <ParallaxLayer offset={7} speed={2} style={{ backgroundColor: '#fff' }} />

                <VideoLayerReverse
                    offsetData={7}
                    speedData={0.5}
                    txtColor={'black'}
                    h1Txt={LanguageContextValue.language['realizations-title-08']}
                    pTxt={LanguageContextValue.language['realizations-description-08']}
                    videoPoster={Poster08}
                    videoSrc={Video08}

                />

                <ParallaxLayer offset={7} speed={5.5} style={{zIndex:"11"}}>
                    <h1 style={{color:"#000", fontSize:"35em", opacity:"0.1", textAlign:"right"}}>08</h1>
                </ParallaxLayer>

                <ParallaxLayer offset={8} speed={2} style={{ backgroundColor: '#000' }} />

                <VideoLayer
                    offsetData={8}
                    speedData={0.5}
                    txtColor={'white'}
                    h1Txt={LanguageContextValue.language['realizations-title-09']}
                    pTxt={LanguageContextValue.language['realizations-description-09']}
                    videoPoster={Poster09}
                    videoSrc={Video09}

                />

                <ParallaxLayer offset={8} speed={3.5} style={{zIndex:"11"}}>
                    <h1 style={{color:"#fff", fontSize:"35em", opacity:"0.1"}}>09</h1>
                </ParallaxLayer>

                <ParallaxLayer offset={9} speed={2} style={{ backgroundColor: '#fff' }} />

                <VideoLayerReverse
                    offsetData={9}
                    speedData={0.5}
                    txtColor={'black'}
                    h1Txt={LanguageContextValue.language['realizations-title-10']}
                    pTxt={LanguageContextValue.language['realizations-description-10']}
                    videoPoster={Poster10}
                    videoSrc={Video10}

                />

                <ParallaxLayer offset={9} speed={2.5} style={{zIndex:"11"}}>
                    <h1 style={{color:"#000", fontSize:"35em", opacity:"0.1", textAlign:"right"}}>10</h1>
                </ParallaxLayer>


                <ParallaxLayer offset={10} speed={2} style={{ backgroundColor: '#000' }} />

                <VideoLayer
                    offsetData={10}
                    speedData={0.5}
                    txtColor={'white'}
                    h1Txt={LanguageContextValue.language['realizations-title-11']}
                    pTxt={LanguageContextValue.language['realizations-description-11']}
                    videoPoster={Poster11}
                    videoSrc={Video11}

                />

                <ParallaxLayer offset={10} speed={4.5} style={{zIndex:"11"}}>
                    <h1 style={{color:"#fff", fontSize:"35em", opacity:"0.1"}}>11</h1>
                </ParallaxLayer>

                <ParallaxLayer offset={11} speed={2} style={{ backgroundColor: '#fff' }} />

                <VideoLayerReverse
                    offsetData={11}
                    speedData={0.5}
                    txtColor={'black'}
                    h1Txt={LanguageContextValue.language['realizations-title-12']}
                    pTxt={LanguageContextValue.language['realizations-description-12']}
                    videoPoster={Poster12}
                    videoSrc={Video12}

                />


                <ParallaxLayer offset={11} speed={2.5} style={{zIndex:"11"}}>
                    <h1 style={{color:"#000", fontSize:"35em", opacity:"0.1", textAlign:"right"}}>12</h1>
                </ParallaxLayer>

                <ParallaxLayer offset={12} speed={2} style={{ backgroundColor: '#000' }} />

                <VideoLayer
                    offsetData={12}
                    speedData={0.5}
                    txtColor={'white'}
                    h1Txt={LanguageContextValue.language['realizations-title-13']}
                    pTxt={LanguageContextValue.language['realizations-description-13']}
                    videoPoster={Poster13}
                    videoSrc={Video13}

                />

                <ParallaxLayer offset={12} speed={1.5} style={{zIndex:"11"}}>
                    <h1 style={{color:"#fff", fontSize:"35em", opacity:"0.1"}}>13</h1>
                </ParallaxLayer>


                <ParallaxLayer offset={13} speed={2} style={{ backgroundColor: '#fff' }} />

                <VideoLayerReverse
                    offsetData={13}
                    speedData={0.5}
                    txtColor={'black'}
                    h1Txt={LanguageContextValue.language['realizations-title-14']}
                    pTxt={LanguageContextValue.language['realizations-description-14']}
                    videoPoster={Poster14}
                    videoSrc={Video14}

                />

                <ParallaxLayer offset={13} speed={5.5} style={{zIndex:"11"}}>
                    <h1 style={{color:"#000", fontSize:"35em", opacity:"0.1", textAlign:"right"}}>14</h1>
                </ParallaxLayer>


                {/* 15, 16, 17 & 18 */}
                <ParallaxLayer offset={14} speed={2} style={{ backgroundColor: '#000' }} />

                <VideoLayer
                    offsetData={14}
                    speedData={0.5}
                    txtColor={'white'}
                    h1Txt={LanguageContextValue.language['realizations-title-15']}
                    pTxt={LanguageContextValue.language['realizations-description-15']}
                    videoPoster={Poster15}
                    videoSrc={Video15}

                />

                <ParallaxLayer offset={14} speed={1.5} style={{zIndex:"11"}}>
                    <h1 style={{color:"#fff", fontSize:"35em", opacity:"0.1"}}>15</h1>
                </ParallaxLayer>        

                {/* 15, 16, 17 & 18 */}
                <ParallaxLayer offset={15} speed={2} style={{ backgroundColor: '#fff' }} />

                <VideoLayerReverse
                    offsetData={15}
                    speedData={0.5}
                    txtColor={'black'}
                    h1Txt={LanguageContextValue.language['realizations-title-16']}
                    pTxt={LanguageContextValue.language['realizations-description-16']}
                    videoPoster={Poster16}
                    videoSrc={Video16}

                />

                <ParallaxLayer offset={15} speed={5.5} style={{zIndex:"11"}}>
                    <h1 style={{color:"#000", fontSize:"35em", opacity:"0.1", textAlign:"right"}}>16</h1>
                </ParallaxLayer>
           {/* 15, 16, 17 & 18 */}
           <ParallaxLayer offset={16} speed={2} style={{ backgroundColor: '#000' }} />

            <VideoLayer
                offsetData={16}
                speedData={0.5}
                txtColor={'white'}
                h1Txt={LanguageContextValue.language['realizations-title-17']}
                pTxt={LanguageContextValue.language['realizations-description-17']}
                videoPoster={Poster17}
                videoSrc={Video17}

            />

            <ParallaxLayer offset={16} speed={1.5} style={{zIndex:"11"}}>
                <h1 style={{color:"#fff", fontSize:"35em", opacity:"0.1"}}>17</h1>
            </ParallaxLayer>        

            {/* 15, 16, 17 & 18 */}
            <ParallaxLayer offset={17} speed={2} style={{ backgroundColor: '#fff' }} />

            <VideoLayerReverse
                offsetData={17}
                speedData={0.5}
                txtColor={'black'}
                h1Txt={LanguageContextValue.language['realizations-title-18']}
                pTxt={LanguageContextValue.language['realizations-description-18']}
                videoPoster={Poster18}
                videoSrc={Video18}

            />

            <ParallaxLayer offset={17} speed={5.5} style={{zIndex:"11"}}>
                <h1 style={{color:"#000", fontSize:"35em", opacity:"0.1", textAlign:"right"}}>18</h1>
            </ParallaxLayer>


            </Parallax>
        </div>
    )
}

export default VideoPlayerNew;
