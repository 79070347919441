//video-react https://video-react.js.org/
import "../../node_modules/video-react/dist/video-react.css"; // import css
import { Player } from 'video-react';

import React,{ Component, useState } from 'react';
import ReactDOM from 'react-dom';

import { Col, Row, Container } from 'react-bootstrap';
import './components_css/VideoPlayer.css';

import { Switch, Route, NavLink, Link, HashRouter } from 'react-router-dom';

import Carousel from 'react-elastic-carousel';
import VideoCard from './VideoCard';

import { animated, useTransition } from 'react-spring';


const breakPoints = [
    {width:1, itemsToShow: 1},
    {width:768, itemsToShow: 2},
    {width:1200, itemsToShow: 3},

]

const VideoPlayerTests = () => {
        const [isVisible, setIsVisible] = useState(false);

        const transition = useTransition(isVisible, {
            from: { x: -100, y: 800, opacity: 0 },
            enter: { x: 0, y: 0, opacity: 1},
            leave: { x: 100, y: 800, opacity: 0}
        });

        return(
            <div>

                <button onClick={() => {
                        setIsVisible(v => !v);
                    }} style={{marginLeft:'50%'}}>{isVisible ? 'un-mount' : 'mount'}</button>

         
                {transition((style, item) =>
                    item ? <animated.div style={style} className="item"/> : ''
                )}

                
            </div>

        )    
    
}

export default VideoPlayerTests;