import headerVideo from '../videos/header_video_fret.mp4';
import './components_css/Header.css';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import HeaderOverlay from './HeaderOverlay';

function Header(){

    return(
        <Container fluid className="header-video-container" id="header_link">

            <div className="inner-div">

                <div className="overlay-div">
                    <HeaderOverlay />
                </div>
                
                <video className='App-video' autoPlay loop muted>
                    <source src={headerVideo} type='video/mp4' />
                </video>
              
            </div>
            
        </Container>
    )
}

export default Header;