import './components_css/Carrousel.css';

import Carousel from 'react-elastic-carousel';
import CarrouselCard from './CarrouselCard';


import img10 from '../images/carrousel_images/10.JPG';
import img14 from '../images/carrousel_images/14.JPG';
import img20 from '../images/carrousel_images/20.JPG';
import img21 from '../images/carrousel_images/21.JPG';
import img36 from '../images/carrousel_images/36.JPG';
import img42 from '../images/carrousel_images/42.JPG';
import img44 from '../images/carrousel_images/44.JPG';
import img53 from '../images/carrousel_images/53.JPG';

import studioA from '../images/carrousel_images/studioA.jpg'
import studioB from '../images/carrousel_images/studioB.jpg'
import studioC from '../images/carrousel_images/studioC.jpg'
import studioD from '../images/carrousel_images/studioD.jpg'
import studioE from '../images/carrousel_images/studioE.jpg'
import studioF from '../images/carrousel_images/studioF.jpg'


// Carousel screen breakpoints :
const breakPoints = [
    {width:1, itemsToShow: 1},
    {width:768, itemsToShow: 2},
    {width:1200, itemsToShow: 3}
]

function Carrousel(){
    return(
        
        <div>
            <div className="empty-div-for-margin"></div>
        <Carousel 
            breakPoints={breakPoints}
            easing="cubic-bezier(.68,.88,.74,.92)"
            tiltEasing="cubic-bezier(0.110, 1, 1.000, 0.210)"
            transitionMs={500} >

            <CarrouselCard cNumber={studioB} />
            <CarrouselCard cNumber={img42} />
            <CarrouselCard cNumber={img53} />
            <CarrouselCard cNumber={img10} />
            <CarrouselCard cNumber={img14} />
            <CarrouselCard cNumber={img20} />
            <CarrouselCard cNumber={img21} />
            <CarrouselCard cNumber={img36} />
            <CarrouselCard cNumber={img44} />
            <CarrouselCard cNumber={studioA} />

        </Carousel>
        </div>
    )
}

export default Carrousel;