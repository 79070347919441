import { Container } from "react-bootstrap";
import './components_css/Footer.css';
import LanguageContext from "./LanguageContext";
import React, { useState, useContext, useEffect } from 'react';


function Footer(){

    const [currentYear, setCurrentYear] = useState(new Date().getFullYear());


    const LanguageContextValue = useContext(LanguageContext)

    useEffect(() => {
        setCurrentYear(new Date().getFullYear());
      }, []);

    return(
        <Container fluid className="footer-container">
            <p> 
                {LanguageContextValue.language['footer-copy-p-part-a']}
                {currentYear}
                {LanguageContextValue.language['footer-copy-p-part-b']}
            </p>
        </Container>
    )
}

export default Footer;