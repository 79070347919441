import { Col, Row, Container } from "react-bootstrap";
import './components_css/ContactContent.css';
import ContactForm from './ContactForm';
import 'aos/dist/aos.css';

import LanguageContext from './LanguageContext';
import React, { useState, useContext } from "react";

function ContactContent(){


    function converJSONToHTML() {
        return {
           __html: LanguageContextValue.language['home-contact-question']
        };
     }; 

    const LanguageContextValue = useContext(LanguageContext)

    return(
        <Container fluid className="ContactContent-container-fluid" id="contact_link"> 
            <Container>
                <Row>
                    <Col lg={2} md={6} xs={12}
                        data-aos="zoom-in">
                        <h1>{LanguageContextValue.language['home-contact-fret']}</h1>
                        <a href="mailto:contact@fretmusique.com">contact@fretmusique.com</a>
                        <br></br>
                        <a href="mailto:fretmusic@outlook.com">fretmusic@outlook.com</a>
                    </Col>
                 
                    <Col lg={2} md={6} xs={12}
                        data-aos="zoom-in-left">
                        <ul>
                            <li><span>+ </span><a href="#header_link">{LanguageContextValue.language['home-contact-nav-home']}</a></li>
                            <li><span>+ </span><a href="#services_link">{LanguageContextValue.language['home-contact-nav-services']}</a></li>
                            <li><span>+ </span><a href="#media_link">{LanguageContextValue.language['home-contact-nav-medias']}</a></li>
                            <li><span>+ </span><a href="#studio_link">{LanguageContextValue.language['home-contact-nav-studio']}</a></li>
                        </ul>
                    </Col>
                    
                    <Col lg={8} md={12} xs={12}>
                        <div className="contact-left-div"
                            data-aos="zoom-in-down">
                            <h1 dangerouslySetInnerHTML={converJSONToHTML()}>
                                {/* {LanguageContextValue.language['home-contact-question']} */}
                                </h1>
                        </div>
                        <div className="contact-right-div"
                            data-aos="zoom-in-left">
                            <p>
                                {LanguageContextValue.language['home-contact-text']}
                            </p>
                        </div>
                        <div className="contact-form-div"
                            data-aos="zoom-in">
                            
                            <ContactForm />
                        </div>
                    </Col>
                </Row>
            </Container>        
        </Container>       
    )
}

export default ContactContent;