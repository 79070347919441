import { Col, Row, Container } from "react-bootstrap";

import './components_css/PartenairesContent.css';

import Partenaire01 from '../images/partenaire_01r.png';
import Partenaire02 from '../images/partenaire_02r.png';
import Partenaire03 from '../images/partenaire_03r.png';
import Partenaire04 from '../images/partenaire_04rc.png';
import Partenaire05 from '../images/partenaire_05r.png';
import Partenaire06 from '../images/partenaire_06.png';
import Partenaire07 from '../images/partenaire_07.png';
import Partenaire08 from '../images/partenaire_08.png';
import Partenaire09 from '../images/partenaire_09.png';
import Partenaire10 from '../images/partenaire_10.png';

import 'aos/dist/aos.css';

import LanguageContext from './LanguageContext';
import React, { useState, useContext } from "react"

function PartenairesContent(){

    function convertJSONToHTML() {
        return {
            __html: LanguageContextValue.language['home-partenaires-text']
        };
    };

    const LanguageContextValue = useContext(LanguageContext)

    return(
        <Container fluid className="PartenairesContent-container-fluid">
            <Container>
                <Row className="partenaires-details-row">
                    <Col lg={12} md={12} sm={12} xs={12} 
                        data-aos="zoom-in">
                        <p dangerouslySetInnerHTML={convertJSONToHTML()}>
                            
                        </p>
                    </Col>
                </Row>
                <Row className="partenaires-logo-row">
                    <Col 
                        lg={{ size: 2, offset: 0 }} 
                        sm={6} 
                        xs={12} 
                        className="partenaires-col"
                        data-aos="zoom-in"
                        >
                            <a href="https://www.cjetrdc.com" target="_blank">
                                <img src={Partenaire01}></img>
                            </a>
                    </Col>
                    <Col 
                        lg={{ size: 2, offset: 0 }} 
                        sm={6} 
                        xs={12} 
                        className="partenaires-col"
                        data-aos="zoom-in"
                        >
                            <a href="https://www.mdiamondfilms.com/" target="_blank">
                                <img src={Partenaire02}></img>
                            </a>
                    </Col>
                    {/* <Col 
                        lg={{ size: 2, offset: 0 }} 
                        sm={6} 
                        xs={12} 
                        className="partenaires-col"
                        data-aos="zoom-in"
                        >
                            <a href="https://www.nixonvideos.com" target="_blank">
                                <img src={Partenaire03}></img>
                            </a>
                    </Col> */}
                    <Col 
                        lg={{ size: 2, offset: 0 }} 
                        sm={6} 
                        xs={12} 
                        className="partenaires-col"
                        data-aos="zoom-in"
                        >
                            <a href="https://www.behance.net/arimazo" target="_blank">
                                <img src={Partenaire04}></img>
                            </a>
                    </Col>
                    <Col 
                        lg={{ size: 2, offset: 0 }} 
                        sm={6} 
                        xs={12} 
                        className="partenaires-col"
                        data-aos="zoom-in"
                        >
                            <a href="https://www.pixelcg.ca/" target="_blank">
                                <img src={Partenaire05}></img>
                            </a>
                    </Col>  
                    <Col 
                        lg={{ size: 2, offset: 0 }} 
                        sm={6} 
                        xs={12} 
                        className="partenaires-col"
                        data-aos="zoom-in"
                        >
                            {/* <a href="https://beatbymaxter.com/index.php" target="_blank"> */}
                                <img src={Partenaire06} style={{height:"90%"}}></img>
                            {/* </a> */}
                    </Col>  
                    {/* <Col 
                        lg={{ size: 2, offset: 0 }}
                        sm={6}
                        xs={12}
                        className="partenaires-col"
                        data-aos="zoom-in"
                        >
                            <a href="https://fortunesfavorgame.com" target="_blank">
                                <img src={Partenaire07} style={{height:"90%"}}></img>
                            </a>
                    </Col> */}
                    <Col
                        lg={{ size: 2, offset: 0 }}
                        sm={6}
                        xs={12}
                        className="partenaires-col"
                        data-aos="zoom-in"
                    >
                        <a href="https://cgtr.ca/" target="_blank">
                            <img src={Partenaire08} style={{height:"90%"}}></img>
                        </a>
                    </Col>
                    {/* <Col
                        lg={{ size: 2, offset: 0 }}
                        sm={6}
                        xs={12}
                        className="partenaires-col"
                        data-aos="zoom-in"
                    >
                        <a href="https://art-media.ca/agence-de-communication/portfolio-agence/" target="_blank">
                            <img src={Partenaire09} style={{height:"90%"}}></img>
                        </a>
                    </Col> */}
                    <Col
                        lg={{ size: 2, offset: 0 }}
                        sm={6}
                        xs={12}
                        className="partenaires-col"
                        data-aos="zoom-in"
                    >
                        <a href="https://art-media.ca/" target="_blank">
                            <img src={Partenaire10} style={{height:"90%"}}></img>
                        </a>
                    </Col>
                </Row>
            </Container>
        </Container>
    )
}

export default PartenairesContent;