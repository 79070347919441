import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import React, {Fragment} from 'react';
import SideBarMenu from './components/SideBarMenu';
import 'aos/dist/aos.css';

//WAS USING BrowserRouter, but seem to return 404 errors on GITHUBPAGES
import {
    BrowserRouter as Router,
    HashRouter,
    Switch,
    Route,
    Link
  } from "react-router-dom";
import AudioPLayerApp from './AudioPlayerApp';
import ImagesApp from './ImagesApp';
import VideoPlayerApp from './VideoPlayerApp';
import Home from './Home';
import AudioPlayerApp from './AudioPlayerApp';
import HollywoodVideo from './components/HollywoodVideo';
import VideoPlayer from './components/VideoPlayer';
import VideoPlayerTests from './components/VideoPlayerTests';

export default function Routes() {

  return (
      // <Router basename={process.env.PUBLIC_URL}>    
      <HashRouter>

        <SideBarMenu pageWrapId={"page-wrap"} outerContainerId={"app"} />

        <Switch>
            <Route exact path="/creations" component={AudioPLayerApp}></Route>
            <Route exact path="/galerie" component={ImagesApp}></Route>
            <Route exact path="/realisations" component={VideoPlayerApp}></Route>
            <Route exact path="/realisations/test" component={VideoPlayerTests}></Route>
            <Route exact path="/realisations/hollywood" component={HollywoodVideo}></Route>
            <Route path='/' component={Home}></Route>
        </Switch>
      </HashRouter>

      // </Router>
    );
  }

  // MAYBE WILL NEED TO REMOVE THE KEYWORD <<EXACT>> from the ROUTE TO /