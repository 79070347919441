import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import './components_css/HeaderOverlay.css';
import SeparatorRune from '../images/rune_separator.png';

import LogoResizedAndCompressed from '../images/fret_logo_resized_2.png';

import 'aos/dist/aos.css';
import { useEffect } from 'react';

import { gsap } from 'gsap';
import { Power0 } from 'gsap/gsap-core';

import LanguageContext from './LanguageContext';
import React, { useState, useContext } from "react"


function HeaderOverlay(){

    const LanguageContextValue = useContext(LanguageContext)

    var t_button = gsap.timeline({delay:0.9});
   
    useEffect(()=>{
        t_button.from(".anim_button", {y:10, opacity:0, ease: Power0.easeOut, delay:0.05}, 'Start');
    }, [])

    return(
        <Container className="video-overlay-container"> 
            <Row>
                <Col className="nav-col d-none d-lg-block" lg={3} md={3}>
                    <div className="nav-div-left"
                        data-aos="zoom-in-down">
                        <ul>
                            <li><span>+ </span><a href="#">{LanguageContextValue.language['home-header-nav-home']}</a></li>
                            <li><span>+ </span><a href="#services_link">{LanguageContextValue.language['home-header-nav-services']}</a></li>
                            <li><span>+ </span><a href="#media_link">{LanguageContextValue.language['home-header-nav-medias']}</a></li>
                            <li><span>+ </span><a href="#studio_link">{LanguageContextValue.language['home-header-nav-studio']}</a></li>
                        </ul>
                    </div>
                    <div className="nav-div-right"
                        data-aos="zoom-in-down">
                        <img src={SeparatorRune}></img>
                    </div>
                </Col>

                <Col className="logo-col" lg={3} md={3} sm={12} xs={12}
                    data-aos="zoom-in-down">  
                    <img src={ LogoResizedAndCompressed }></img>  
                </Col>

                <Col className="txt-col d-none d-lg-block" lg={6} md={6} sm={12} xs={12}
                    data-aos="zoom-in-down">
                    <p>
                        {LanguageContextValue.language['home-header-text']}
                    </p>
                </Col>
             
            </Row>
            <div className="button-div anim_button">
                <Button 
                    variant="dark" 
                    size="lg" 
                    href="#contact_link"
                    style={{backgroundColor: "#00aeef", border: "none", color:"white"}}>{LanguageContextValue.language['home-header-button-contact']}</Button>
            </div>
        </Container>
    )
}

export default HeaderOverlay;