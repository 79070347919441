import { Col, Row, Container } from "react-bootstrap";
import './components_css/Media.css';
import RuneRaidho from '../images/Rune_Raidho.png';
import 'aos/dist/aos.css';
import LanguageContext from './LanguageContext';
import React, { useState, useContext } from "react"

function Partenaires(){

    const LanguageContextValue = useContext(LanguageContext)

    return(
        <Container className="media-container">
            <Row>
                <Col md={4}></Col>
            
                <Col md={8}>
                    <div className="media-left-div"
                        data-aos="zoom-in-left">
                        <h1>{LanguageContextValue.language['home-primary-title-partenaires']}</h1>
                    </div>
                    <div className="media-mid-div"
                        data-aos="zoom-in-right">
                        <img src={RuneRaidho}></img>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}

export default Partenaires;