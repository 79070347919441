import { slide as Menu } from 'react-burger-menu';
import { HashRouter, Link } from 'react-router-dom';
import './components_css/SideBarMenu.css';
import LanguageContext from './LanguageContext';
import React, { useState, useContext } from "react"

import fr from '../languages/fr.json'
import en from '../languages/en.json'

export default props =>{

    const LanguageContextValue = useContext(LanguageContext)

    function toggleFrench(){
        LanguageContextValue.updateLanguage(en=>fr)
    }

    function toggleEnglish(){
        LanguageContextValue.updateLanguage(fr=>en)
    }

    const [isOpen, setOpen] = useState(false)

    const handleIsOpen = () => {
        setOpen(!isOpen)
    }
    
    const closeSideBar = () => {
        setOpen(false)
    }

    const redirectToContact = () => {
        closeSideBar();
        setTimeout(function(){
            window.location.href = '#contact_link';
        },100);
    }

    return(
        <Menu {...props}
            isOpen={isOpen}
            onOpen={handleIsOpen}
            onClose={handleIsOpen}>

            <Link to="/" onClick={closeSideBar}><span>+</span> {LanguageContextValue.language['sidebarnav-home']}</Link>
           
            <Link to="/creations" onClick={closeSideBar}><span>+</span> {LanguageContextValue.language['sidebarnav-creations']}</Link>

            <Link to ="/galerie" onClick={closeSideBar}><span>+</span> {LanguageContextValue.language['sidebarnav-gallery']}</Link>
       
            <Link to ="/realisations" onClick={closeSideBar}><span>+</span> {LanguageContextValue.language['sidebarnav-realizations']}</Link>

            <Link to="/" onClick={redirectToContact}><span>+</span> {LanguageContextValue.language['sidebarnav-contact']}</Link>

            <div id="div-language">

                <button onClick={toggleFrench} className="language-button"><span>-</span> Français <span>-</span></button>
                <br></br>
                <button onClick={toggleEnglish} className="language-button"><span>-</span> English <span>-</span></button>

            </div>
            
        </Menu>
    );
};