import React from 'react'
import VideoPlayer from './components/VideoPlayer';

import { Switch, Route, NavLink, Link, HashRouter } from 'react-router-dom';
import VideoPlayerNew from "./components/VideoPlayerNew";


function VideoPlayerApp(){
    return(
       
            // <VideoPlayer/>
       <VideoPlayerNew/>
        // <div>
        //     <h1 style={{textAlign:'center',marginTop:'5em',color:'white'}}>
        //         Video Player App Page
        //     </h1>
        //     <div style={{margin:'auto', width:'35%', padding:'10px'}}>
        //         <p style={{textAlign:'justify',marginTop:'2em',color:'white',fontSize:'1em'}}>
        //             <h3>Todo :</h3><br></br>
        //             [ ] - Sketch a design for that page on paper<br></br>
        //             [ ] - Implement a dynamic search bar<br></br>
        //             [ ] - Hover on videoscreenshot inspired by Netflix<br></br>
        //             &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;[ ] - The thubnail scale on hover (animation should be smooth)<br></br>
        //             &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;[ ] - The screenshot is replaced by the video and it start without sound<br></br>
        //             &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;[ ] - Display video basic information<br></br>
        //             &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;[ ] - On click it gatter the opening of the video itself<br></br>
        //             [ ] - Custom video controller<br></br>
        //         </p>
        //     </div>
        // </div>
    )
}

export default VideoPlayerApp;