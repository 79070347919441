import { Col, Row, Container } from "react-bootstrap";
import './components_css/Services.css';
import runeFehuServices from '../images/rune_fehu_services_blue.png';
import 'aos/dist/aos.css';

import LanguageContext from './LanguageContext';
import React, { useState, useContext } from "react"

function Studio(){

    const LanguageContextValue = useContext(LanguageContext)

    return(
        <Container className="services-container" id="studio_link">
            <Row>
                <Col lg={12} md={12} sm={12} xs={12}>
                    <div className="content-container-div">
                        <div data-aos="zoom-in-up">
                            <img src={runeFehuServices}></img>
                        </div>
                        <div data-aos="zoom-in-left">
                            <h1>{LanguageContextValue.language['home-primary-title-studio']}</h1>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}

export default Studio;