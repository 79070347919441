import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './components_css/Media.css';
import RuneRaidho from '../images/Rune_Raidho.png';
import 'aos/dist/aos.css';
import LanguageContext from './LanguageContext';
import React, { useState, useContext } from "react"

function Media(){

    function convertJSONToHTML(jsonKey){
        return{
            __html: LanguageContextValue.language[jsonKey]
        };
    };

    const LanguageContextValue = useContext(LanguageContext)

    return(
        <Container className="media-container" id="media_link">
            <Row>
                <Col md={4}></Col>
            
                <Col md={8}>
                    <div className="media-left-div"
                        data-aos="fade-left">
                        <h1>{LanguageContextValue.language['home-primary-title-medias']}</h1>
                    </div>
                    <div className="media-mid-div"
                            data-aos="zoom-in">
                        <img src={RuneRaidho}></img>
                    </div>
                    <div className="media-right-div"
                        data-aos="fade-right">
                        <h5 dangerouslySetInnerHTML={convertJSONToHTML('home-secondary-title-medias')}></h5>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}

export default Media;